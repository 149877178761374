import React, { ForwardedRef, forwardRef } from 'react';
import {
  callPeriodObservationTypes,
  StepTypes,
  CallTypes,
  basketTypeOptions,
  ObservationFrequencyOptions,
} from '../../../product-schema/pdw-select-options';
import { isWeekend } from '../../../utilities/utilities';
import {
  ExpandableNumberFieldset,
  PFDatePicker,
  PFSelect,
  PFNumberInput,
} from '../reusable-form-component/BaseFormComponents';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { callDetailsFields } from '../../../product-schema/pdw-field-subsets';
import PFCheckbox from '../reusable-form-component/BaseFormComponents/PFCheckbox';

const CallDetailsFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const form = useProductFormContext();
    return (
      <div className={'form-section'} ref={ref}>
        <div className={'form-subsection'}>
          <PFSelect
            label="Call Type"
            data={[...Object.values(CallTypes)]}
            fieldPath={callDetailsFields.callType}
          />
          <PFCheckbox
            label={'Call Memory'}
            fieldPath={callDetailsFields.callMemory}
          />
          <PFSelect
            label="Call Basket Type"
            data={basketTypeOptions}
            fieldPath={callDetailsFields.callBasketType}
          />
          <PFSelect
            label="Call Observation Frequency"
            data={ObservationFrequencyOptions}
            fieldPath={callDetailsFields.callObservationFrequency}
          />
          <PFSelect
            label="Call Period Observation Type"
            data={callPeriodObservationTypes}
            fieldPath={callDetailsFields.callPeriodObservationType}
          />
          <ExpandableNumberFieldset
            primaryField={{
              property: callDetailsFields.callBarrierLevelFinal,
              label: 'Call Barrier Level',
            }}
            subFields={[
              {
                property: callDetailsFields.callBarrierLevelHigh,
                label: 'High Call Barrier Level',
              },
              {
                property: callDetailsFields.callBarrierLevelLow,
                label: 'Low Call Barrier Level',
              },
              {
                property: callDetailsFields.callBarrierLevelCurrent,
                label: 'Current Call Barrier Level',
              },
            ]}
          />

          {form?.values?.productCall?.callType === CallTypes.AUTOCALL_STEP && (
            <>
              <PFNumberInput
                decimalScale={6}
                // this is stored as a whole number, no need to parse
                rightSection={<div>%</div>}
                label="Call Barrier Step Increment"
                fieldPath={callDetailsFields.callBarrierStepIncrement}
              ></PFNumberInput>
            </>
          )}
        </div>

        <div className={'form-subsection'}>
          <PFNumberInput
            label={'Number of No Call Periods'}
            fieldPath={callDetailsFields.numberNoCallPeriods}
          ></PFNumberInput>
          <ExpandableNumberFieldset
            primaryField={{
              property: callDetailsFields.callPremiumFinal,
              label: 'Call Premium',
            }}
            subFields={[
              {
                property: callDetailsFields.callPremiumHigh,
                label: 'High Call Premium',
              },
              {
                property: callDetailsFields.callPremiumLow,
                label: 'Low Call Premium',
              },
              {
                property: callDetailsFields.callPremiumCurrent,
                label: 'Current Call Premium',
              },
            ]}
          />

          {(form?.values?.productCall?.callType === CallTypes.AUTOCALL_STEP ||
            form?.values?.productCall?.callType ===
              CallTypes.ISSUER_CALL_STEP) && (
            <>
              <PFNumberInput
                decimalScale={6}
                // this is stored as a whole number, no need to parse
                rightSection={<div>%</div>}
                label="Call Premium Step Increment"
                fieldPath={callDetailsFields.callPremiumStepIncrement}
              />

              <PFSelect
                label="Step Type"
                data={[...Object.values(StepTypes)]}
                fieldPath={callDetailsFields.stepType}
              />
            </>
          )}
          <PFDatePicker
            label="Expected Maturity"
            excludeDate={isWeekend}
            fieldPath={callDetailsFields.expectedMaturity}
          ></PFDatePicker>
        </div>
      </div>
    );
  },
);
CallDetailsFormComponent.displayName = 'CallDetailsFormComponent';
export default CallDetailsFormComponent;
