import React, { forwardRef, useContext } from 'react';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { TextInput, TextInputProps } from '@mantine/core';
import { isFieldDisabled } from '../../../../utilities/utilities';
import { FormFieldPath } from './FieldInput.interface';
import { ProductFormHiddenFieldsContext } from '../../entry-and-edit-contexts/HiddenFields-context';
import { useProductFormDisabled } from '../../entry-and-edit-contexts/disabled-fields-context';
import { derivableFieldValues } from '../../../../product-schema/pdw-field-subsets';

export interface PFTextInputProps
  extends TextInputProps,
    React.RefAttributes<HTMLInputElement>,
    FormFieldPath {}

const PFTextInput = forwardRef<HTMLInputElement, PFTextInputProps>(
  ({ fieldPath, ...rest }: PFTextInputProps, ref) => {
    const disabledContext = useProductFormDisabled();
    const form = useProductFormContext();
    const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
    const { value, onChange, onFocus, onBlur, error } =
      form.getInputProps(fieldPath);
    return (
      <>
        {(!hiddenFieldsContext.hiddenFields.includes(fieldPath) ||
          hiddenFieldsContext.showAllHiddenFields) && (
          <TextInput
            ref={ref}
            autoComplete={'off'}
            {...{ onChange, onFocus, onBlur, error }}
            value={value ? value : ''}
            maw={280}
            {...rest}
            styles={{
              label: {
                textDecoration: derivableFieldValues.includes(fieldPath)
                  ? 'underline'
                  : 'unset',
              },
            }}
            disabled={
              rest.disabled || isFieldDisabled(disabledContext, fieldPath)
            }
          ></TextInput>
        )}
      </>
    );
  },
);

PFTextInput.displayName = 'PFTextInput';
export default PFTextInput;
