import React, { useContext } from 'react';
import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { isFieldDisabled } from '../../../../utilities/utilities';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { FormFieldPath } from './FieldInput.interface';
import { ProductFormHiddenFieldsContext } from '../../entry-and-edit-contexts/HiddenFields-context';
import { useProductFormDisabled } from '../../entry-and-edit-contexts/disabled-fields-context';
import { derivableFieldValues } from '../../../../product-schema/pdw-field-subsets';

export interface PFMultiSelectProps
  extends MultiSelectProps,
    React.RefAttributes<HTMLInputElement>,
    FormFieldPath {}

const PFMultiSelect = ({ fieldPath, ...rest }: PFMultiSelectProps) => {
  const disabledContext = useProductFormDisabled();
  const form = useProductFormContext();
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
  const { value } = form.getInputProps(fieldPath);
  return (
    <>
      {(!hiddenFieldsContext.hiddenFields.includes(fieldPath) ||
        hiddenFieldsContext.showAllHiddenFields) && (
        <MultiSelect
          {...form.getInputProps(fieldPath)}
          value={value != null ? value : []}
          maw={280}
          {...rest}
          styles={{
            label: {
              textDecoration: derivableFieldValues.includes(fieldPath)
                ? 'underline'
                : 'unset',
            },
          }}
          disabled={
            rest.disabled || isFieldDisabled(disabledContext, fieldPath)
          }
        ></MultiSelect>
      )}
    </>
  );
};

export default PFMultiSelect;
