import axios from 'axios';
import { PRODUCT_DATA_WAREHOUSE_ROOT } from './api-consts';

export const getPdwProductByCusipOrIsin = (cusip: string) =>
  axios.get(`${PRODUCT_DATA_WAREHOUSE_ROOT}/products/${cusip}`);

export const getProductByCriteriaWithAudit = (
  searchCriteria: SearchCriteria,
  pageRequest: PageRequest,
) => {
  return axios.post<CanonicalProductSearchResult>(
    `${PRODUCT_DATA_WAREHOUSE_ROOT}/products/searchCriteria/auditRecord?page=${pageRequest.page}&size=${pageRequest.size}&sort=${pageRequest.sort}`,
    searchCriteria,
  );
};
export interface CanonicalProductSearchResult {
  content: any[];
  totalElements: number;
  size: number;
}

export interface SearchCriteria {
  searchCriteriaList: {
    key: string;
    operation: string;
    value: string[];
  }[];
  junctionOperation: string;
}

export interface PageRequest {
  page: number;
  size: number;
  sort: string;
}
