export const derivableFields = {
  standardPrice: 'productGeneral.standardPrice',
  // tenorFinal: 'productGeneral.tenorFinal',
  // tenorUnit: 'productGeneral.tenorUnit',
  underlierReturnCapLevelFinal: 'productGrowth.underlierReturnCapLevelFinal',
  underlierReturnCapLevelLow: 'productGrowth.underlierReturnCapLevelLow',
  underlierReturnCapLevelHigh: 'productGrowth.underlierReturnCapLevelHigh',
  underlierReturnCapLevelMedium: 'productGrowth.underlierReturnCapLevelMedium',
  capitalProtectionLevelFinal: 'productProtection.capitalProtectionLevelFinal',
  protectionLevel: 'productProtection.protectionLevel',
  paymentRatePerAnnumFinal: 'productYield.paymentRatePerAnnumFinal',
  paymentRatePerAnnumLow: 'productYield.paymentRatePerAnnumLow',
  paymentRatePerAnnumHigh: 'productYield.paymentRatePerAnnumHigh',
  paymentRatePerPeriodFinal: 'productYield.paymentRatePerPeriodFinal',
  paymentRatePerPeriodLow: 'productYield.paymentRatePerPeriodLow',
  paymentRatePerPeriodHigh: 'productYield.paymentRatePerPeriodHigh',
  minPaymentRatePerAnnumFinal: 'productYield.minPaymentRatePerAnnumFinal',
  minPaymentRatePerAnnumHigh: 'productYield.minPaymentRatePerAnnumHigh',
  minPaymentRatePerAnnumLow: 'productYield.minPaymentRatePerAnnumLow',
  digitalReturnBarrierFinal: 'productGrowth.digitalReturnBarrierFinal',
  lowerCalleStrikeFinal: 'productGrowth.lowerCalleStrikeFinal',
  putStrikeFinal: 'productProtection.putStrikeFinal',
};

export const derivableFieldValues = [...Object.values(derivableFields)];

export const CoreDetailsFields = {
  stage: 'productGeneral.stage',
  status: 'productGeneral.status',
  issuer: 'productGeneral.issuer',
  completionStatus: 'productGeneral.completionStatus',
  cusip: 'productGeneral.cusip',
  cins: 'productGeneral.cins',
  opsReviewStatus: 'productGeneral.opsReviewStatus',
  isin: 'productGeneral.isin',
  wrapperType: 'productGeneral.wrapperType',
  registrationType: 'productGeneral.registrationType',
  tenorUnit: 'productGeneral.tenorUnit',
  settlementType: 'productGeneral.settlementType',
  currency: 'productGeneral.currency',
  estimatedInitialValue: 'productGeneral.estimatedInitialValue',
  productLaunchTimestamp: 'productGeneral.productLaunchTimestamp',
  productDeadlineTimestamp: 'productGeneral.productDeadlineTimestamp',
  productFaceValue: 'productGeneral.productFaceValue',
  fairPriceFinal: 'productGeneral.fairPriceFinal',
  calculationAgent: 'productGeneral.calculationAgent',
  accountType: 'productGeneral.accountType',
  minimumTradingLot: 'productGeneral.minimumTradingLot',
  productName: 'productGeneral.productName',
  structureNameExternal: 'productGeneral.structureNameExternal',
  structureShortNameExternal: 'productGeneral.structureShortNameExternal',
  structureLongNameExternal: 'productGeneral.structureLongNameExternal',
  performanceStrike: 'productGeneral.performanceStrike',
  fundservID: 'productGeneral.fundservID',
  incompleteCategory: 'productGeneral.incompleteCategory',
  tenorFinal: 'productGeneral.tenorFinal',
  tenorHigh: 'productGeneral.tenorHigh',
  tenorLow: 'productGeneral.tenorLow',
  tenorCurrent: 'productGeneral.tenorCurrent',
  productNotionalFinal: 'productGeneral.productNotionalFinal',
  productNotionalHigh: 'productGeneral.productNotionalHigh',
  productNotionalLow: 'productGeneral.productNotionalLow',
  productNotionalCurrent: 'productGeneral.productNotionalCurrent',
  issuePriceFinal: 'productGeneral.issuePriceFinal',
  issuePriceHigh: 'productGeneral.issuePriceHigh',
  issuePriceLow: 'productGeneral.issuePriceLow',
  issuePriceCurrent: 'productGeneral.issuePriceCurrent',
};

export const underlierFields = {
  underlierList: 'productGeneral.underlierList',
  basketType: 'productGeneral.basketType',
  basketReturnAdjustment: 'productGeneral.basketReturnAdjustment',
  underlierRankWeightList: 'productGeneral.underlierRankWeightList',
};

export const productDatesFields = {
  tradeDate: 'productGeneral.tradeDate',
  strikeDate: 'productGeneral.strikeDate',
  issueDate: 'productGeneral.issueDate',
  finalValuationDate: 'productGeneral.finalValuationDate',
  maturityDate: 'productGeneral.maturityDate',
  businessDayConvention: 'productGeneral.businessDayConvention',
};

export const callDetailsFields = {
  callType: 'productCall.callType',
  callObservationFrequency: 'productCall.callObservationFrequency',
  callMemory: 'productCall.callMemory',
  numberNoCallPeriods: 'productCall.numberNoCallPeriods',
  callPeriodObservationType: 'productCall.callPeriodObservationType',
  expectedMaturity: 'productCall.expectedMaturity',
  callBarrierStepIncrement: 'productCall.callBarrierStepIncrement',
  callPremiumStepIncrement: 'productCall.callPremiumStepIncrement',
  stepType: 'productCall.stepType',
  callBarrierLevelFinal: 'productCall.callBarrierLevelFinal',
  callBarrierLevelHigh: 'productCall.callBarrierLevelHigh',
  callBarrierLevelLow: 'productCall.callBarrierLevelLow',
  callBarrierLevelCurrent: 'productCall.callBarrierLevelCurrent',
  callPremiumFinal: 'productCall.callPremiumFinal',
  callPremiumHigh: 'productCall.callPremiumHigh',
  callPremiumLow: 'productCall.callPremiumLow',
  callPremiumCurrent: 'productCall.callPremiumCurrent',
  callBasketType: 'productCall.callBasketType',
};

export const couponDetailsFields = {
  paymentType: 'productYield.paymentType',
  paymentStepType: 'productYield.paymentStepType',
  paymentEvaluationFrequencyFinal:
    'productYield.paymentEvaluationFrequencyFinal',
  paymentEvaluationFrequencyCurrent:
    'productYield.paymentEvaluationFrequencyCurrent',
  paymentBarrierObservationFrequency:
    'productYield.paymentBarrierObservationFrequency',
  paymentFrequency: 'productYield.paymentFrequency',
  minCouponPayPeriod: 'productYield.minCouponPayPeriod',
  variablePayInitialObservationDate:
    'productYield.variablePayInitialObservationDate',
  variablePayFinalObservationDate:
    'productYield.variablePayFinalObservationDate',
  paymentTypeChangeDate: 'productYield.paymentTypeChangeDate',
  interest: 'productYield.interest',
  optionPremium: 'productYield.optionPremium',
  rateBuilderGrouping: 'productYield.rateBuilderGrouping',
  rateBuilderReturns: 'productYield.rateBuilderReturns',
  paymentBarrierFinal: 'productYield.paymentBarrierFinal',
  paymentBarrierHigh: 'productYield.paymentBarrierHigh',
  paymentBarrierLow: 'productYield.paymentBarrierLow',
  paymentBarrierCurrent: 'productYield.paymentBarrierCurrent',
  paymentRatePerPeriodFinal: 'productYield.paymentRatePerPeriodFinal',
  paymentRatePerPeriodHigh: 'productYield.paymentRatePerPeriodHigh',
  paymentRatePerPeriodLow: 'productYield.paymentRatePerPeriodLow',
  paymentRatePerPeriodCurrent: 'productYield.paymentRatePerPeriodCurrent',
  minPaymentRatePerPeriodFinal: 'productYield.minPaymentRatePerPeriodFinal',
  minPaymentRatePerPeriodHigh: 'productYield.minPaymentRatePerPeriodHigh',
  minPaymentRatePerPeriodLow: 'productYield.minPaymentRatePerPeriodLow',
  minPaymentRatePerPeriodCurrent: 'productYield.minPaymentRatePerPeriodCurrent',
  stockReturnFloorFinal: 'productYield.stockReturnFloorFinal',
  stockReturnFloorHigh: 'productYield.stockReturnFloorHigh',
  stockReturnFloorLow: 'productYield.stockReturnFloorLow',
  stockReturnFloorCurrent: 'productYield.stockReturnFloorCurrent',
  stockReturnCapFinal: 'productYield.stockReturnCapFinal',
  stockReturnCapHigh: 'productYield.stockReturnCapHigh',
  stockReturnCapLow: 'productYield.stockReturnCapLow',
  stockReturnCapCurrent: 'productYield.stockReturnCapCurrent',
  minPaymentRatePerAnnumFinal: 'productYield.minPaymentRatePerAnnumFinal',
  minPaymentRatePerAnnumHigh: 'productYield.minPaymentRatePerAnnumHigh',
  minPaymentRatePerAnnumLow: 'productYield.minPaymentRatePerAnnumLow',
  minPaymentRatePerAnnumCurrent: 'productYield.minPaymentRatePerAnnumCurrent',
  upperBarrierLevelFinal: 'productYield.upperBarrierLevelFinal',
  upperBarrierLevelHigh: 'productYield.upperBarrierLevelHigh',
  upperBarrierLevelLow: 'productYield.upperBarrierLevelLow',
  upperBarrierLevelCurrent: 'productYield.upperBarrierLevelCurrent',
  paymentRatePerAnnumFinal: 'productYield.paymentRatePerAnnumFinal',
  paymentRatePerAnnumHigh: 'productYield.paymentRatePerAnnumHigh',
  paymentRatePerAnnumLow: 'productYield.paymentRatePerAnnumLow',
  paymentRatePerAnnumCurrent: 'productYield.paymentRatePerAnnumCurrent',
  rateBuilderValuesFinal: 'productYield.rateBuilderValuesFinal',
  rateBuilderValuesHigh: 'productYield.rateBuilderValuesHigh',
  rateBuilderValuesLow: 'productYield.rateBuilderValuesLow',
  rateBuilderValuesCurrent: 'productYield.rateBuilderValuesCurrent',
  couponBasketType: 'productYield.couponBasketType',
};

export const CalendarDatesFields = {
  paymentDateList: 'productYield.paymentDateList',
  callObservationDateList: 'productCall.callObservationDateList',
};

export const productGrowthFields = {
  growthType: 'productGrowth.growthType',
  digitalReturnBarrierObservationFrequency:
    'productGrowth.digitalReturnBarrierObservationFrequency',
  bearish: 'productGrowth.bearish',
  upsideParticipationRateFinal: 'productGrowth.upsideParticipationRateFinal',
  upsideParticipationRateHigh: 'productGrowth.upsideParticipationRateHigh',
  upsideParticipationRateLow: 'productGrowth.upsideParticipationRateLow',
  upsideParticipationRateCurrent:
    'productGrowth.upsideParticipationRateCurrent',
  underlierReturnCapLevelFinal: 'productGrowth.underlierReturnCapLevelFinal',
  underlierReturnCapLevelHigh: 'productGrowth.underlierReturnCapLevelHigh',
  underlierReturnCapLevelLow: 'productGrowth.underlierReturnCapLevelLow',
  underlierReturnCapLevelCurrent:
    'productGrowth.underlierReturnCapLevelCurrent',
  maximumReturnFinal: 'productGrowth.maximumReturnFinal',
  maximumReturnHigh: 'productGrowth.maximumReturnHigh',
  maximumReturnLow: 'productGrowth.maximumReturnLow',
  maximumReturnCurrent: 'productGrowth.maximumReturnCurrent',
  digitalReturnBarrierFinal: 'productGrowth.digitalReturnBarrierFinal',
  digitalReturnBarrierHigh: 'productGrowth.digitalReturnBarrierHigh',
  digitalReturnBarrierLow: 'productGrowth.digitalReturnBarrierLow',
  digitalReturnBarrierCurrent: 'productGrowth.digitalReturnBarrierCurrent',
  digitalReturnFinal: 'productGrowth.digitalReturnFinal',
  digitalReturnHigh: 'productGrowth.digitalReturnHigh',
  digitalReturnLow: 'productGrowth.digitalReturnLow',
  digitalReturnCurrent: 'productGrowth.digitalReturnCurrent',
  absoluteReturnBarrierLevelHigh:
    'productGrowth.absoluteReturnBarrierLevelHigh',
  absoluteReturnBarrierLevelLow: 'productGrowth.absoluteReturnBarrierLevelLow',
  absoluteReturnBarrierLevelCurrent:
    'productGrowth.absoluteReturnBarrierLevelCurrent',
  absoluteReturnBarrierLevelFinal:
    'productGrowth.absoluteReturnBarrierLevelFinal',
  lowerCallStrikeList: 'productGrowth.lowerCallStrikeList',
  upsideParticipationAboveDigitalReturnFinal:
    'productGrowth.upsideParticipationAboveDigitalReturnFinal',
  upsideParticipationAboveDigitalReturnHigh:
    'productGrowth.upsideParticipationAboveDigitalReturnHigh',
  upsideParticipationAboveDigitalReturnLow:
    'productGrowth.upsideParticipationAboveDigitalReturnLow',
  upsideParticipationAboveDigitalReturnCurrent:
    'productGrowth.upsideParticipationAboveDigitalReturnCurrent',
  absoluteReturnParticipationRateFinal:
    'productGrowth.absoluteReturnParticipationRateFinal',
  absoluteReturnParticipationRateHigh:
    'productGrowth.absoluteReturnParticipationRateHigh',
  absoluteReturnParticipationRateLow:
    'productGrowth.absoluteReturnParticipationRateLow',
  absoluteReturnParticipationRateCurrent:
    'productGrowth.absoluteReturnParticipationRateCurrent',
  knockOutBarrierObservationFrequency:
    'productGrowth.knockOutBarrierObservationFrequency',
};

export const protectionFields = {
  downsideType: 'productProtection.downsideType',
  putObservationFrequency: 'productProtection.putObservationFrequency',
  protectionLevel: 'productProtection.protectionLevel',
  putStrikeFinal: 'productProtection.putStrikeFinal',
  oneStarLevel: 'productProtection.oneStarLevel',
  principalBarrierLevelHigh: 'productProtection.principalBarrierLevelHigh',
  principalBarrierLevelLow: 'productProtection.principalBarrierLevelLow',
  principalBarrierLevelFinal: 'productProtection.principalBarrierLevelFinal',
  principalBarrierLevelCurrent:
    'productProtection.principalBarrierLevelCurrent',
  principalBufferLevelFinal: 'productProtection.principalBufferLevelFinal',
  principalBufferLevelHigh: 'productProtection.principalBufferLevelHigh',
  principalBufferLevelLow: 'productProtection.principalBufferLevelLow',
  principalBufferLevelCurrent: 'productProtection.principalBufferLevelCurrent',
  putLeverageFinal: 'productProtection.putLeverageFinal',
  putLeverageHigh: 'productProtection.putLeverageHigh',
  putLeverageLow: 'productProtection.putLeverageLow',
  putLeverageCurrent: 'productProtection.putLeverageCurrent',
  capitalProtectionLevelHigh: 'productProtection.capitalProtectionLevelHigh',
  capitalProtectionLevelLow: 'productProtection.capitalProtectionLevelLow',
  capitalProtectionLevelCurrent:
    'productProtection.capitalProtectionLevelCurrent',
  capitalProtectionLevelFinal: 'productProtection.capitalProtectionLevelFinal',

  finalFixingCrossing: 'productProtection.finalFixingCrossing',
  KIbarrierCrossing: 'productProtection.KIbarrierCrossing',
};

export const preferenceFields = {
  offeringType: 'productGeneral.offeringType',
  countryDistribution: 'productRegulatory.countryDistribution',
  listing: 'productRegulatory.listing',
  secondaryMarketQuotingType: 'productGeneral.secondaryMarketQuotingType',
  targetMarket: 'productRegulatory.targetMarket',
};

export const feeDetailsFields = {
  reofferClientList: 'productGeneral.reofferClientList',
  structureFee: 'productGeneral.structureFee',
  standardPrice: 'productGeneral.standardPrice',
  salesCreditFinal: 'productGeneral.salesCreditFinal',
  salesCreditHigh: 'productGeneral.salesCreditHigh',
  salesCreditLow: 'productGeneral.salesCreditLow',
  salesCreditCurrent: 'productGeneral.salesCreditCurrent',
};

export const clnDetailsFields = {
  accrualFieldReference: 'productCln.accrualFieldReference',
  cdsName: 'productCln.cdsName',
  cdsTenor: 'productCln.cdsTenor',
  debtIsinList: 'productCln.debtIsinList',
  debtWeightList: 'productCln.debtWeightList',
  collateral: 'productCln.collateral',
};

export const cmsDetailsFields = {
  floatRateFixingOffsetDays: 'productCln.floatRateFixingOffsetDays',
  floatRateFixingTime: 'productCln.floatRateFixingTime',
  floatInstrument: 'productCln.floatInstrument',
  floatingRateObservationFrequency:
    'productCln.floatingRateObservationFrequency',
  fixedCouponMultiplier: 'productCln.fixedCouponMultiplier',
  interestMultiplierPerYear: 'productCln.interestMultiplierPerYear',
  floatSpread: 'productYield.floatSpread',
  ratePaymentType: 'productCln.ratePaymentType',
  rateBarrierObservationFrequency: 'productCln.rateBarrierObservationFrequency',
  shortTermRateUnderlierSymbol: 'productCms.shortTermRateUnderlierSymbol',
  shortTermRateUnderlierSource: 'productCms.shortTermRateUnderlierSource',
  longTermRateUnderlierSymbol: 'productCms.longTermRateUnderlierSymbol',
  longTermRateUnderlierSource: 'productCms.longTermRateUnderlierSource',
  floatRateFixingSchedule: 'productCln.floatRateFixingSchedule',
  leverageFactors: 'productYield.leverageFactors',
  rateBarrierLevel: 'productCln.rateBarrierLevel',
  rateCap: 'productCln.rateCap',
  rateConditionalCoupon: 'productCln.rateConditionalCoupon',
  rateMin: 'productCln.rateMin',
  inflationMonthOffset: 'productCms.inflationMonthOffset',
};

export const optionDetailsFields = {
  optionType: 'productOptionDetails.optionType',
  optionStrikeLevel: 'productOptionDetails.optionStrikeLevel',
  optionObservationType: 'productOptionDetails.optionObservationType',
  optionNumberOfShares: 'productOptionDetails.optionNumberOfShares',
};

export const equityBuilderFields = {
  equityPool: 'productYield.equityPool',
  triggerParticipationRate: 'productYield.triggerParticipationRate',
  cashPoolKnockInVolume: 'productYield.cashPoolKnockInVolume',
  cashPoolKnockInLevel: 'productYield.cashPoolKnockInLevel',
};

export const nonCoreDetailsFields = {
  productNotionalCap: 'productGeneral.productNotionalCap',
  totalPnl: 'productGeneral.totalPnl',
  swapMaturity: 'productGeneral.swapMaturity',
  longTermCapitalGain: 'productGeneral.longTermCapitalGain',
  oisDfPercentage: 'productGeneral.oisDfPercentage',
  upfrontDeferred: 'productGeneral.upfrontDeferred',
  dayCountFraction: 'productGeneral.dayCountFraction',
  originator: 'productGeneral.originator',
};

export const ancillaryFeaturesFields = {
  ancillaryFeatures: 'productGeneral.ancillaryFeatures',
  lookbackInitialDate: 'productGeneral.lookbackInitialDate',
  lookbackInitialValuationType: 'productGeneral.lookbackInitialValuationType',
  lookbackFinalDate: 'productGeneral.lookbackFinalDate',
  lookbackFinalValuationType: 'productGeneral.lookbackFinalValuationType',
  minimumReturnFinal: 'productGrowth.minimumReturnFinal',
  minimumReturnLow: 'productGrowth.minimumReturnLow',
  minimumReturnHigh: 'productGrowth.minimumReturnHigh',
  minimumReturnCurrent: 'productGrowth.minimumReturnCurrent',
  averagingInDates: 'productGeneral.averagingInDates',
  averagingExclusion: 'productGeneral.averagingExclusion',
  averagingOutDates: 'productGeneral.averagingOutDates',
  aggregatingDates: 'productGeneral.aggregatingDates',
  knockOutBarrierFinal: 'productGrowth.knockOutBarrierFinal',
  knockOutBarrierHigh: 'productGrowth.knockOutBarrierHigh',
  knockOutBarrierLow: 'productGrowth.knockOutBarrierLow',
  knockOutBarrierCurrent: 'productGrowth.knockOutBarrierCurrent',
  knockOutRebateFinal: 'productGrowth.knockOutRebateFinal',
  knockOutRebateHigh: 'productGrowth.knockOutRebateHigh',
  knockOutRebateLow: 'productGrowth.knockOutRebateLow',
  knockOutRebateCurrent: 'productGrowth.knockOutRebateCurrent',
  supplementalPercentage: 'productGeneral.supplementalPercentage',
};

export const clientSpecificFields = {
  clientProductId: 'clientSpecific.clientProductId',
  productCode: 'clientSpecific.productCode',
  offshoreEligibility: 'clientSpecific.offshoreEligibility',
  locked: 'clientSpecific.locked',
  prosRequestId: 'clientSpecific.prosRequestId',
  dealLead: 'clientSpecific.dealLead',
  productSymbol: 'clientSpecific.productSymbol',
  legalTeam: 'clientSpecific.legalTeam',
  issuerCreditCode: 'clientSpecific.issuerCreditCode',
  securityId: 'clientSpecific.securityId',
  dealBackup: 'clientSpecific.dealBackup',
  internalTradingDesk: 'clientSpecific.internalTradingDesk',
  ramCode: 'clientSpecific.ramCode',
  trader: 'clientSpecific.trader',
  pvFundingLessFeesPercentage: 'clientSpecific.pvFundingLessFeesPercentage',
  legalPrintEstimate: 'clientSpecific.legalPrintEstimate',
  breakpointShares: 'clientSpecific.breakpointShares',
  numBreakpointInvestors: 'clientSpecific.numBreakpointInvestors',
  broadOffering: 'clientSpecific.broadOffering',
  coreApproval: 'clientSpecific.coreApproval',
  interestPeriod: 'clientSpecific.interestPeriod',
  firstPaymentDate: 'clientSpecific.firstPaymentDate',
  originatorDeskId: 'clientSpecific.originatorDeskId',
};

export const allFormFields = [
  ...Object.values(CoreDetailsFields),
  ...Object.values(underlierFields),
  ...Object.values(productDatesFields),
  ...Object.values(callDetailsFields),
  ...Object.values(couponDetailsFields),
  ...Object.values(productGrowthFields),
  ...Object.values(protectionFields),
  ...Object.values(preferenceFields),
  ...Object.values(feeDetailsFields),
  ...Object.values(clnDetailsFields),
  ...Object.values(cmsDetailsFields),
  ...Object.values(equityBuilderFields),
  ...Object.values(nonCoreDetailsFields),
  ...Object.values(ancillaryFeaturesFields),
  ...Object.values(clientSpecificFields),
];
