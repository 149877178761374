import classes from './calendarDates.module.scss';
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import dayjs from 'dayjs';
import {
  CallTypes,
  CouponTypes,
  ObservationFrequencies,
  TenorUnits,
} from '../../../../product-schema/pdw-select-options';
import { DateInput } from '@mantine/dates';
import {
  convertTenorToMonths,
  createAccurateDateWithoutTime,
  formatDate,
  isWeekend,
} from '../../../../utilities/utilities';
import {
  Button,
  Indicator,
  Menu,
  Modal,
  NumberInput,
  Radio,
  Select,
} from '@mantine/core';
import { IconMenu2, IconTrash } from '@tabler/icons-react';
import { ArrayNumberItem } from '../../../../components/ArrayNumberItem';
import NewCalendarDate from './NewCalendarDate';
import { colors } from '../../../../Styles/colors';
import DateCalculator from './DateCalculator';
import {
  calculateBarrierPremium,
  processDateCalculationPayload,
} from './oldDateCalcLogic';

import { quickInputDateParser } from '../../../../utilities/parcers-and-formatters/quick-input-date-parser';
import { v4 } from 'uuid';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  decimalToWholeNumber,
  wholeNumberToDecimal,
} from '../../product-form-mappings';
import { CalendarDateCopy } from './CalendarDateCopy';
import { CalendarDateExcelUpload } from './CalendarDateExcelUpload';

export enum DateRowRadioOptions {
  CALL = 'call',
  COUPON = 'coupon',
  BOTH = 'both',
}

export interface DateRow {
  id?: any;
  resetDate?: Date | null;
  observationDate: Date | null;
  settlementDate: Date | null;
  callBarrierLevelStepValue?: number | null;
  callPremiumStepValue?: number | null;
  paymentRateValue?: number | null;
  paymentBarrierLevelValue?: number | null;
  dateType: DateRowRadioOptions | null;
}

export const newDateRow = {
  id: null,
  resetDate: null,
  observationDate: null,
  settlementDate: null,
  callBarrierLevelStepValue: null,
  callPremiumStepValue: null,
  dateType: null,
};

export const emptyCallDateObj = {
  callObservationDate: null,
  callSettlementDate: null,
  callPremiumStepValue: null,
  callBarrierLevelStepValue: null,
  callScheduleUnderlierList: null,
};

export const emptyCouponDateObj = {
  paymentObservationDate: null,
  paymentSettlementDate: null,
  accrualEndDate: null,
  paymentBarrierLevel: null,
  paymentRate: null,
  paymentRateAbsolute: null,
  bifurcationOfInterest: null,
  interest: null,
  optionPremium: null,
  paymentResetDate: null,
};

export const getCallObservationDateList = (rows: DateRow[]) => {
  return rows
    .filter(
      (row) =>
        row.dateType === DateRowRadioOptions.CALL ||
        row.dateType === DateRowRadioOptions.BOTH,
    )
    .map((x: DateRow) => {
      return {
        ...emptyCallDateObj,
        callObservationDate: formatDate(x.observationDate),
        callSettlementDate: formatDate(x.settlementDate),
        callPremiumStepValue: wholeNumberToDecimal(x.callPremiumStepValue),
        callBarrierLevelStepValue: wholeNumberToDecimal(
          x.callBarrierLevelStepValue,
        ),
      };
    });
};

export const getPaymentDateList = (rows: DateRow[]) => {
  return rows
    .filter(
      (row) =>
        row.dateType === DateRowRadioOptions.COUPON ||
        row.dateType === DateRowRadioOptions.BOTH,
    )
    .map((x: DateRow) => {
      return {
        ...emptyCouponDateObj,
        paymentObservationDate: formatDate(x.observationDate),
        paymentSettlementDate: formatDate(x.settlementDate),
        paymentResetDate: x.resetDate ? formatDate(x.resetDate) : null,
        paymentRate: wholeNumberToDecimal(x.paymentRateValue),
        paymentBarrierLevel: wholeNumberToDecimal(x.paymentBarrierLevelValue),
      };
    });
};

export const areDateRowsTheSame = (date1: DateRow, date2: DateRow): boolean => {
  return (
    (dayjs(date1.observationDate).isSame(date2.observationDate, 'day') ||
      (date1.observationDate == null && date2.observationDate == null)) &&
    (dayjs(date1.settlementDate).isSame(date2.settlementDate, 'day') ||
      (date1.settlementDate == null && date2.settlementDate == null))
  );
};

export const dateListReducerFunction = (
  dates: DateRow[],
  currentDate: DateRow,
) => {
  const existingDateIndex = dates.findIndex((x) =>
    areDateRowsTheSame(x, currentDate),
  );
  if (existingDateIndex < 0) {
    dates.push(currentDate);
  } else {
    if (dates[existingDateIndex].dateType !== currentDate.dateType) {
      dates[existingDateIndex].dateType = DateRowRadioOptions.BOTH;
    }
    if (currentDate.callBarrierLevelStepValue) {
      dates[existingDateIndex].callBarrierLevelStepValue =
        currentDate.callBarrierLevelStepValue;
    }
    if (currentDate.callPremiumStepValue != null) {
      // premium can be 0
      dates[existingDateIndex].callPremiumStepValue =
        currentDate.callPremiumStepValue;
    }
  }
  return dates;
};

export interface CalendarDatesProps {
  holidayDates?: string[];
}

const CalendarDates = forwardRef(
  (
    { holidayDates = [] }: CalendarDatesProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [fullscreenModalIsOpen, setFullscreenModalIsOpen] =
      useState<boolean>(false);
    const form = useProductFormContext();
    // const disableFields = isFieldDisabled(disabledContext, 'productYield.leverageFactors');
    // const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
    // const [resetDateVisible, setResetDateVisible] = useState(false);
    const [lastChangedRowId, setLastChangedRowId] = useState<string | null>(
      null,
    );
    const [verticalTab, setVerticalTab] = useState(false);
    const [dateList, setDateList] = useState<DateRow[]>([]);
    const [initialDateList, setInitialDateList] = useState<DateRow[]>([]);

    const [createNewRowAs, setCreateNewRowAs] = useState<DateRowRadioOptions>(
      DateRowRadioOptions.BOTH,
    );

    // The scrollable element for your list
    const parentRef = React.useRef(null);

    // The virtualizer
    const rowVirtualizer = useVirtualizer({
      count: dateList.length,
      getScrollElement: () => parentRef.current,
      estimateSize: () => 85,
    });

    // when the form values change, update the dateList state on this component
    useEffect(() => {
      const dateRows: DateRow[] = [
        ...(form.values.productYield?.paymentDateList ?? []).map((e: any) => ({
          ...emptyCouponDateObj,
          resetDate: createAccurateDateWithoutTime(e.paymentResetDate),
          observationDate: createAccurateDateWithoutTime(
            e.paymentObservationDate,
          ),
          settlementDate: createAccurateDateWithoutTime(
            e.paymentSettlementDate,
          ),
          dateType: DateRowRadioOptions.COUPON,
          paymentBarrierLevelValue: decimalToWholeNumber(e.paymentBarrierLevel),
          paymentRateValue: decimalToWholeNumber(e.paymentRate),
        })),
        ...(form.values.productCall?.callObservationDateList ?? []).map(
          (e: any) => ({
            ...emptyCallDateObj,
            observationDate: createAccurateDateWithoutTime(
              e.callObservationDate,
            ),
            settlementDate: createAccurateDateWithoutTime(e.callSettlementDate),
            callBarrierLevelStepValue: e.callBarrierLevelStepValue
              ? decimalToWholeNumber(e.callBarrierLevelStepValue)
              : null,
            callPremiumStepValue: e.callPremiumStepValue
              ? decimalToWholeNumber(e.callPremiumStepValue)
              : e.callPremiumStepValue, // allow premium to be 0
            dateType: DateRowRadioOptions.CALL,
          }),
        ),
      ]
        .reduce(dateListReducerFunction, [])
        .map((x) => {
          return { ...x, id: v4() };
        });
      setDateList(dateRows);
      setInitialDateList(dateRows);
    }, [
      form.values.productCall.callObservationDateList,
      form.values.productYield.paymentDateList,
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    const callFieldsVisible =
      form.values.productCall.callType === CallTypes.AUTOCALL_STEP;
    const callPremiumVisible =
      form.values.productCall.callType === CallTypes.ISSUER_CALL_STEP;
    const couponStepFieldsVisible = !!form.values.productYield.paymentStepType;

    const resetDateVisible =
      form.values.productYield.paymentType === CouponTypes.FIXED_TO_FLOATING ||
      form.values.productYield.paymentType === CouponTypes.FLOATING_TO_FIXED ||
      form.values.productYield.paymentType === CouponTypes.FIXED_TO_RESET ||
      form.values.productYield.paymentType ===
        CouponTypes.VARIABLE_INTEREST_CREDIT_LINKED ||
      form.values.productYield.paymentType ===
        CouponTypes.RESETTING_ANNUAL_AVERAGING ||
      form.values.productYield.paymentType ===
        CouponTypes.RESETTING_TIME_SERIES;

    const sortByObservationDate = (a: DateRow, b: DateRow) => {
      if (a.observationDate == null && b.observationDate != null) {
        return -1;
      }
      if (a.observationDate != null && b.observationDate == null) {
        return 1;
      }
      if (a.observationDate == null && b.observationDate == null) {
        return 0;
      }

      return (
        dayjs(a.observationDate).diff(dayjs(b.observationDate)) || // if they are equal this will be zero, and it compares the other date
        dayjs(a.settlementDate).diff(dayjs(b.settlementDate))
      );
    };

    const sortBySettlementDate = (a: DateRow, b: DateRow) => {
      if (a.settlementDate == null && b.settlementDate != null) {
        return -1;
      }
      if (a.settlementDate != null && b.settlementDate == null) {
        return 1;
      }
      if (a.settlementDate == null && b.settlementDate == null) {
        return 0;
      }
      return (
        dayjs(a.settlementDate).diff(dayjs(b.settlementDate)) ||
        dayjs(a.observationDate).diff(dayjs(b.observationDate))
      );
    };

    const handleAddNewRow = () => {
      // setDateList([...dateList, {...newDateRow, id: v4(), dateType: createNewRowAs}]);
      setDateList([
        ...dateList,
        { ...newDateRow, id: v4(), dateType: createNewRowAs },
        // ...Array.from(Array(50).keys()).map(() => ({...newDateRow, id: v4(), dateType: createNewRowAs}))
      ]);
    };

    const handleAddMultipleDates = (newDateRows: DateRow[]) => {
      updateRows([...dateList, ...newDateRows], null, true);
      // setDateList([...dateList, ...newDateRows.map(x => ({...x, id: v4()}))]);
    };

    const handleAddDate = (dateRow: DateRow) => {
      if (fullscreenModalIsOpen) {
        setDateList([...dateList, dateRow]);
        setLastChangedRowId(dateRow.id);
      } else {
        if (
          dateRow.dateType === DateRowRadioOptions.CALL ||
          dateRow.dateType === DateRowRadioOptions.BOTH
        ) {
          const callDateObj = {
            ...emptyCallDateObj,
            callObservationDate: formatDate(dateRow.observationDate),
            callSettlementDate: formatDate(dateRow.settlementDate),
            // dateType: DateRowRadioOptions.COUPON,
          };
          if (!form.values.productCall.callObservationDateList) {
            form.setFieldValue('productCall.callObservationDateList', [
              callDateObj,
            ] as any[]);
          } else {
            form.insertListItem(
              'productCall.callObservationDateList',
              callDateObj,
            );
          }
        }
        if (
          dateRow.dateType === DateRowRadioOptions.COUPON ||
          dateRow.dateType === DateRowRadioOptions.BOTH
        ) {
          const couponDateObj = {
            ...emptyCouponDateObj,
            paymentResetDate: formatDate(dateRow.resetDate),
            paymentObservationDate: formatDate(dateRow.observationDate),
            paymentSettlementDate: formatDate(dateRow.settlementDate),
            // dateType: DateRowRadioOptions.CALL
          };
          if (!form.values.productYield.paymentDateList) {
            form.setFieldValue('productYield.paymentDateList', [
              couponDateObj,
            ] as any[]);
          } else {
            form.insertListItem('productYield.paymentDateList', couponDateObj);
          }
        }
      }
    };

    // dateRow.dateType === DateRowRadioOptions.COUPON || dateRow.dateType === DateRowRadioOptions.BOTH
    const calculateResetDates = (
      dateToUse: 'settlementDate' | 'observationDate',
    ) => {
      const updatedResetDateList = [...dateList].sort(sortByObservationDate);
      if (resetDateVisible) {
        let previousDate = null;
        for (let i = 0; i < updatedResetDateList.length; i++) {
          if (
            updatedResetDateList[i].dateType === DateRowRadioOptions.BOTH ||
            updatedResetDateList[i].dateType === DateRowRadioOptions.COUPON
          ) {
            if (previousDate) {
              updatedResetDateList[i].resetDate = previousDate;
            } else {
              updatedResetDateList[i].resetDate = null;
            }
            previousDate =
              dateToUse === 'observationDate'
                ? updatedResetDateList[i].observationDate
                : updatedResetDateList[i].settlementDate;
          }
        }
      }
      setDateList(updatedResetDateList);
      setInitialDateList(updatedResetDateList);
    };

    const handleBlurAndUpdate = () => {
      updateRows(dateList, lastChangedRowId, true);
    };

    const updateRows = (
      newDateList: DateRow[],
      rowId: string | null,
      update?: boolean,
    ) => {
      setLastChangedRowId(rowId);
      if (update) {
        mapDateRowsToPDWModel(newDateList);
      } else {
        setDateList(newDateList);
      }
    };

    const handleDateTypeChange = (value: DateRowRadioOptions, id: string) => {
      const updatedDateList = dateList?.map((row: DateRow) => {
        if (row.id === id) {
          return { ...row, dateType: value };
        } else {
          return row;
        }
      });
      updateRows(updatedDateList, id, true);
    };

    // scroll into view isn't working with virtual scrolling
    // const scrollToRow = (id: string, newDateList: DateRow[], idSubstring: string) => {
    //   console.log('dateList', newDateList, dateList);
    //   newDateList.sort(sortDateRows);
    //   // console.log('sorted', newDateList)
    //     const index = newDateList.findIndex((row: DateRow) => row.id === id);
    //     console.log('index', index, id);
    //     rowVirtualizer.scrollToIndex(index, {align: 'center'})
    //
    //     setTimeout(() => {
    //       const el = document.getElementById(id + idSubstring);
    //       console.log('el', el);
    //       el?.focus()
    //     });
    // }

    const handleSettlementDateChange = (value: Date | null, id: string) => {
      console.log('change', value);
      const newDateList = dateList?.map((row: DateRow) => {
        if (row.id === id) {
          return { ...row, settlementDate: value };
        } else {
          return row;
        }
      });
      updateRows(newDateList, id, value == null);
      // setTimeout(() => scrollToRow(id, newDateList, '-settlement-date'))
    };

    const handleObservationDateChange = (value: Date | null, id: string) => {
      const newDateList = dateList?.map((row: DateRow) => {
        if (row.id === id) {
          return { ...row, observationDate: value };
        } else {
          return row;
        }
      });
      updateRows(newDateList, id, value == null);
      // setTimeout(() => scrollToRow(id, newDateList, '-observation-date'));
    };

    const handleCallPremiumChange = (value: number | string, id: string) => {
      const updatedDateList = dateList?.map((row: DateRow) => {
        if (row.id === id) {
          return {
            ...row,
            callPremiumStepValue: typeof value != 'string' ? +value : undefined,
          }; // premium can be 0
        } else {
          return row;
        }
      });
      setDateList(updatedDateList);
    };

    const handleCallBarrierChange = (value: number | string, id: string) => {
      const updatedDateList = dateList?.map((row: DateRow) => {
        if (row.id === id) {
          return { ...row, callBarrierLevelStepValue: +value || undefined };
        } else {
          return row;
        }
      });
      setDateList(updatedDateList);
    };

    const handlePaymentRateChange = (value: number | string, id: string) => {
      const updatedDateList = dateList?.map((row: DateRow) => {
        if (row.id === id) {
          return { ...row, paymentRateValue: +value || undefined };
        } else {
          return row;
        }
      });
      setDateList(updatedDateList);
    };

    const handlePaymentBarrierLevelChange = (
      value: number | string,
      id: string,
    ) => {
      const updatedDateList = dateList?.map((row: DateRow) => {
        if (row.id === id) {
          return { ...row, paymentBarrierLevelValue: +value || undefined };
        } else {
          return row;
        }
      });
      setDateList(updatedDateList);
    };

    // todo: map the rows onto the pdw model, aka set the form values.
    // the form values updating will cause the component's useeffect to re-create the rows and re set the dateList state
    const mapDateRowsToPDWModel = (rows: DateRow[]) => {
      const filteredRows = rows.filter(
        (row) =>
          row.resetDate ||
          row.observationDate ||
          row.settlementDate ||
          row.callBarrierLevelStepValue ||
          row.callPremiumStepValue ||
          row.paymentBarrierLevelValue ||
          row.paymentRateValue,
      );

      form.setFieldValue(
        'productYield.paymentDateList',
        getPaymentDateList(filteredRows) as any[],
      );
      form.setFieldValue(
        'productCall.callObservationDateList',
        getCallObservationDateList(filteredRows) as any[],
      );
    };

    const removeDateRow = (id: number) => {
      updateRows(
        dateList.filter((x: DateRow) => x.id !== id),
        lastChangedRowId,
        true,
      );
    };

    const getDateError = (d: Date | null | undefined) => {
      if (!d) return null;
      if (holidayDates.includes(formatDate(d) || 'notGonnaBeThere')) {
        return 'This date falls on a holiday';
      } else if (isWeekend(d)) {
        return 'This date falls on a weekend';
      } else {
        return null;
      }
    };

    const calculateSteps = () => {
      const callBarrierLevelFinal =
        form.values.productCall.callBarrierLevelFinal;
      const callBarrierStepIncrement =
        form.values.productCall.callBarrierStepIncrement;
      const callPremiumFinal = form.values.productCall.callPremiumFinal;
      const callPremiumStepIncrement =
        form.values.productCall.callPremiumStepIncrement;
      const tenorInMonths: number | null = convertTenorToMonths(
        form.values.productGeneral.tenorFinal,
        form.values.productGeneral.tenorUnit as TenorUnits,
      );
      if (!tenorInMonths) {
        alert('Tenor and Tenor Units Required');
        return;
      }

      if (!callBarrierLevelFinal) {
        alert('Call Barrier Level Final Required');
        return;
      }

      if (callBarrierStepIncrement == null) {
        alert('Call Barrier Step Increment Required');
        return;
      }

      if (callPremiumFinal == null) {
        alert('Call Premium Final Required');
        return;
      }

      if (callPremiumStepIncrement == null) {
        alert('Call Premium Step Increment Required');
        return;
      }

      if (
        !form.values.productCall.callObservationFrequency &&
        !form.values.productYield.paymentEvaluationFrequencyFinal
      ) {
        alert('Must have Call or Coupon Frequency');
        return;
      }

      if (
        !form.values.productYield.paymentEvaluationFrequencyFinal &&
        (form.values.productCall.callObservationFrequency ===
          ObservationFrequencies.CUSTOM ||
          form.values.productYield.paymentEvaluationFrequencyFinal ===
            ObservationFrequencies.ON_FINAL_VALUATION_DATE)
      ) {
        alert(
          'Cannot calculate steps on the given call observation frequency date call observation frequency',
        );
        return;
      }

      const steps = calculateBarrierPremium(form);

      const updatedDateList = dateList.map((row: DateRow, index: number) => {
        return {
          ...row,
          callBarrierLevelStepValue: steps?.[index]?.barrier,
          callPremiumStepValue: steps?.[index]?.premium,
        };
      });

      if (fullscreenModalIsOpen) {
        setDateList(updatedDateList);
      } else {
        form.setFieldValue(
          'productCall.callObservationDateList',
          getCallObservationDateList(updatedDateList) as any,
        );
      }
    };

    //
    /*
  Gets the amount of rows that have changed between the initial data list coming in from the PDW object directly
  and the current dataList State that the user is interacting with
 */
    const rowsChanged = useMemo(() => {
      const primaryList =
        dateList.length > initialDateList.length ? dateList : initialDateList;
      const secondaryList =
        dateList.length > initialDateList.length ? initialDateList : dateList;
      const dateRowsMatch = (a: any, b: any) => {
        return (
          a.observationDate?.getTime() === b.observationDate?.getTime() &&
          a.settlementDate?.getTime() === b.settlementDate?.getTime() &&
          a.resetDate?.getTime() === b.resetDate?.getTime() &&
          a.callBarrierLevelStepValue === b.callBarrierLevelStepValue &&
          a.callPremiumStepValue === b.callPremiumStepValue &&
          a.paymentBarrierLevelValue === b.paymentBarrierLevelValue &&
          a.paymentRateValue === b.paymentRateValue &&
          a.dateType === b.dateType
        );
      };

      const foo = primaryList.filter((x) =>
        secondaryList.some((y) => dateRowsMatch(x, y)),
      );
      const bar = secondaryList.filter((x) =>
        primaryList.some((y) => dateRowsMatch(x, y)),
      );
      return primaryList.length - new Set([...foo, ...bar]).size;
    }, [dateList, initialDateList]);

    const couponCount = dateList.filter(
      (x) =>
        x.dateType === DateRowRadioOptions.COUPON ||
        x.dateType === DateRowRadioOptions.BOTH,
    ).length;
    const callCount = dateList.filter(
      (x) =>
        x.dateType === DateRowRadioOptions.CALL ||
        x.dateType === DateRowRadioOptions.BOTH,
    ).length;
    const calendarDateContent = (
      <div
        ref={ref}
        style={{
          width: '90%',
          margin: 'auto',
        }}
      >
        <div
          className={classes.newDateSection}
          style={{ top: fullscreenModalIsOpen ? '3em' : '6.6em' }}
        >
          <NewCalendarDate
            handleAddMultipleDates={handleAddMultipleDates}
            handleAddDate={handleAddDate}
            holidayDates={holidayDates}
            getDateError={getDateError}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              marginLeft: 'auto',
            }}
          >
            <div>Total Calls: {callCount}</div>
            <div>Total Coupons: {couponCount}</div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              gap: '2em',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  gap: '1.75em',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {fullscreenModalIsOpen && (
                  <>
                    <Button
                      variant="primary"
                      type={'button'}
                      onClick={handleAddNewRow}
                    >
                      Add New Row
                    </Button>
                    <Select
                      data={[...Object.values(DateRowRadioOptions)]}
                      style={{ width: '8em' }}
                      value={createNewRowAs}
                      onChange={(e) =>
                        setCreateNewRowAs(e as DateRowRadioOptions)
                      }
                    />
                    <Button
                      variant="primary"
                      type={'button'}
                      onClick={() => setVerticalTab(!verticalTab)}
                    >
                      {verticalTab ? 'Tab Col' : 'Tab Row'}
                    </Button>
                  </>
                )}
                <Button
                  variant="primary"
                  type={'button'}
                  onClick={() => {
                    setDateList([...dateList].sort(sortByObservationDate));
                  }}
                >
                  {`Sort by Observation Date`}
                </Button>
                <Button
                  variant="primary"
                  type={'button'}
                  onClick={() => {
                    setDateList([...dateList].sort(sortBySettlementDate));
                  }}
                >
                  {`Sort by Settlement Date`}
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              {resetDateVisible && (
                <Menu>
                  <Menu.Target>
                    <Button variant="outline" type={'button'}>
                      Add Reset Dates
                      <IconMenu2
                        style={{ marginLeft: '10px' }}
                        size={16}
                      ></IconMenu2>
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => calculateResetDates('observationDate')}
                    >
                      Use Observation Date
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => calculateResetDates('settlementDate')}
                    >
                      Use Settlement Date
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
              <CalendarDateCopy />
              <CalendarDateExcelUpload />
              <DateCalculator
                processDatesCallback={(e: any) =>
                  processDateCalculationPayload(e, form)
                }
              />
              {callFieldsVisible && (
                <Button
                  variant="primary"
                  type={'button'}
                  onClick={calculateSteps}
                  disabled={dateList.length === 0}
                >
                  Calculate Steps
                </Button>
              )}
              {fullscreenModalIsOpen ? (
                <Indicator
                  label={rowsChanged}
                  size={16}
                  disabled={rowsChanged === 0}
                >
                  <Button
                    variant="primary"
                    type={'button'}
                    onClick={() => mapDateRowsToPDWModel(dateList)}
                    disabled={rowsChanged === 0}
                  >
                    Apply Changes
                  </Button>
                </Indicator>
              ) : (
                <Button
                  variant="primary"
                  type={'button'}
                  onClick={() => setFullscreenModalIsOpen(true)}
                >
                  Full Screen
                </Button>
              )}
            </div>
          </div>
        </div>

        <div
          ref={parentRef}
          style={{
            height: fullscreenModalIsOpen ? '75vh' : `65vh`,
            overflow: 'auto', // Make it scroll!
          }}
        >
          {/* The large inner element to hold all of the items */}
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualItem) => (
              <div
                key={dateList[virtualItem.index].id}
                style={{
                  margin: '.6em 0',
                  display: 'flex',
                  gap: '2em',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 0,
                  padding: '1em',
                  borderRadius: '4px',
                  border:
                    fullscreenModalIsOpen &&
                    dateList[virtualItem.index].id === lastChangedRowId
                      ? `1px solid ${colors.greyLightest}`
                      : 'none',
                  left: 0,
                  width: '100%',
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                <div style={{ alignSelf: 'flex-end' }}>
                  <ArrayNumberItem>{virtualItem.index + 1}.</ArrayNumberItem>
                </div>
                <DateInput
                  id={dateList[virtualItem.index].id + '-observation-date'}
                  className={classes.fixedInput}
                  clearable={true}
                  disabled={
                    form.values.productYield.paymentType ===
                      CouponTypes.FIXED && !form.values.productCall.callType
                  }
                  tabIndex={verticalTab ? 2 : 0}
                  dateParser={quickInputDateParser}
                  allowDeselect={true}
                  valueFormat={'MM/DD/YYYY'}
                  error={getDateError(
                    dateList[virtualItem.index].observationDate,
                  )}
                  label={'Observation Date'}
                  autoComplete={'off'}
                  placeholder="MM/DD/YYYY"
                  firstDayOfWeek={0}
                  value={dateList[virtualItem.index].observationDate}
                  excludeDate={isWeekend}
                  onBlur={handleBlurAndUpdate}
                  onChange={(value) =>
                    handleObservationDateChange(
                      value,
                      dateList[virtualItem.index].id,
                    )
                  }
                />

                <DateInput
                  id={dateList[virtualItem.index].id + '-settlement-date'}
                  className={classes.fixedInput}
                  clearable={true}
                  tabIndex={verticalTab ? 1 : 0}
                  dateParser={quickInputDateParser}
                  allowDeselect={true}
                  valueFormat={'MM/DD/YYYY'}
                  error={getDateError(
                    dateList[virtualItem.index].settlementDate,
                  )}
                  label={'Settlement Date'}
                  autoComplete={'off'}
                  placeholder="MM/DD/YYYY"
                  firstDayOfWeek={0}
                  value={dateList[virtualItem.index].settlementDate}
                  excludeDate={isWeekend}
                  onBlur={handleBlurAndUpdate}
                  onChange={(value) =>
                    handleSettlementDateChange(
                      value,
                      dateList[virtualItem.index].id,
                    )
                  }
                />

                {resetDateVisible && (
                  <DateInput
                    className={classes.fixedInput}
                    dateParser={quickInputDateParser}
                    valueFormat={'MM/DD/YYYY'}
                    error={getDateError(dateList[virtualItem.index].resetDate)}
                    label={'Reset Date'}
                    autoComplete={'off'}
                    disabled={true}
                    value={dateList[virtualItem.index].resetDate}
                    placeholder={'N/A'}
                  />
                )}

                {couponStepFieldsVisible && (
                  <>
                    <NumberInput
                      className={classes.fixedInputSmall}
                      autoComplete={'off'}
                      rightSection={<div>%</div>}
                      value={
                        dateList[virtualItem.index].paymentRateValue ||
                        undefined
                      }
                      onChange={(value) =>
                        handlePaymentRateChange(
                          value,
                          dateList[virtualItem.index].id,
                        )
                      }
                      onBlur={handleBlurAndUpdate}
                      decimalScale={6}
                      label="Payment Rate"
                    />

                    <NumberInput
                      className={classes.fixedInputSmall}
                      autoComplete={'off'}
                      rightSection={<div>%</div>}
                      value={
                        dateList[virtualItem.index].paymentBarrierLevelValue ||
                        undefined
                      }
                      onChange={(value) =>
                        handlePaymentBarrierLevelChange(
                          value,
                          dateList[virtualItem.index].id,
                        )
                      }
                      onBlur={handleBlurAndUpdate}
                      decimalScale={6}
                      label="Payment Barrier"
                    />
                  </>
                )}

                {callFieldsVisible && (
                  <>
                    <NumberInput
                      className={classes.fixedInputSmall}
                      autoComplete={'off'}
                      rightSection={<div>%</div>}
                      value={
                        dateList[virtualItem.index].callBarrierLevelStepValue ||
                        undefined
                      }
                      onChange={(value) =>
                        handleCallBarrierChange(
                          value,
                          dateList[virtualItem.index].id,
                        )
                      }
                      onBlur={handleBlurAndUpdate}
                      disabled={
                        dateList[virtualItem.index].dateType ===
                        DateRowRadioOptions.COUPON
                      }
                      decimalScale={6}
                      label="Call Barrier"
                    ></NumberInput>
                  </>
                )}
                {(callPremiumVisible || callFieldsVisible) && (
                  <>
                    <NumberInput
                      className={classes.fixedInputSmall}
                      hideControls={true}
                      rightSection={<div>%</div>}
                      autoComplete={'off'}
                      // @ts-ignore
                      value={
                        dateList[virtualItem.index].callPremiumStepValue != null
                          ? dateList[virtualItem.index].callPremiumStepValue
                          : undefined
                      }
                      onChange={(value) =>
                        handleCallPremiumChange(
                          value,
                          dateList[virtualItem.index].id,
                        )
                      }
                      onBlur={handleBlurAndUpdate}
                      disabled={
                        dateList[virtualItem.index].dateType ===
                        DateRowRadioOptions.COUPON
                      }
                      decimalScale={6}
                      label="Call Premium"
                    ></NumberInput>
                  </>
                )}

                <Radio.Group
                  className={'no-padding-radio'}
                  name={dateList[virtualItem.index].id}
                  size="xs"
                  value={dateList[virtualItem.index].dateType as string}
                  onChange={(value: string) => {
                    handleDateTypeChange(
                      value as DateRowRadioOptions,
                      dateList[virtualItem.index].id,
                    );
                  }}
                >
                  <Radio
                    tabIndex={-1}
                    value={DateRowRadioOptions.COUPON}
                    label="Coupon"
                  />
                  <Radio
                    tabIndex={-1}
                    value={DateRowRadioOptions.CALL}
                    label="Call"
                  />
                  <Radio
                    tabIndex={-1}
                    value={DateRowRadioOptions.BOTH}
                    label="Both"
                  />
                </Radio.Group>

                {(dateList?.length || 1) >= 1 && (
                  <Button
                    variant={'secondary-small'}
                    tabIndex={-1}
                    type={'button'}
                    onClick={() =>
                      removeDateRow(dateList[virtualItem.index].id)
                    }
                  >
                    <IconTrash size={18}></IconTrash>
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );

    return (
      <>
        <Modal
          styles={{
            header: {
              padding: '1.25em 2.5em',
            },
          }}
          // todo: compare products should have a useful title/header
          title={'Calendar Date Entry'}
          opened={fullscreenModalIsOpen}
          onClose={() => {
            setFullscreenModalIsOpen(false);
            mapDateRowsToPDWModel(dateList);
          }}
          transitionProps={{ duration: 0 }}
          fullScreen
        >
          {calendarDateContent}
        </Modal>
        <>{!fullscreenModalIsOpen && calendarDateContent}</>
      </>
    );
  },
);

CalendarDates.displayName = 'CalendarDates';
export default CalendarDates;
