import React, { useState } from 'react';
import { DateInput, DatePickerInput } from '@mantine/dates';
import { Button, Group, Radio, Select, Switch, Tooltip } from '@mantine/core';
import { DateRowRadioOptions } from './CalendarDates';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { v4 } from 'uuid';
import { formatDate, isWeekend } from '../../../../utilities/utilities';
import dayjs from 'dayjs';
import { quickInputDateParser } from '../../../../utilities/parcers-and-formatters/quick-input-date-parser';
import { CouponTypes } from '../../../../product-schema/pdw-select-options';
import { notifications } from '@mantine/notifications';
import { useLocalStorage } from '@mantine/hooks';
import { IconCalendar, IconHash } from '@tabler/icons-react';

export interface NewCalendarDateProps {
  handleAddDate: (x: any) => void;
  handleAddMultipleDates: (x: any[]) => void;
  holidayDates: string[];
  getDateError: (x: any) => any;
}

const NewCalendarDate = ({
  handleAddDate,
  handleAddMultipleDates,
  getDateError,
}: NewCalendarDateProps) => {
  const form = useProductFormContext();
  const getTypeForNewRow = () => {
    if (
      !form.values.productCall.callType &&
      form.values.productYield.paymentType
    ) {
      return DateRowRadioOptions.COUPON;
    } else if (
      form.values.productCall.callType &&
      !form.values.productYield.paymentType
    ) {
      return DateRowRadioOptions.CALL;
    } else {
      return DateRowRadioOptions.BOTH;
    }
  };

  const [numberOfDateColumns, setNumberOfDateColumns] = useLocalStorage({
    key: 'calendar-date-entry-column-number',
    defaultValue: 1,
  });
  const [multiDateEntryMode, setMultiDateEntryMode] = useState(false);
  const [settlementDate, setSettlementDate] = useState<Date | null>(null);
  const [observationDate, setObservationDate] = useState<Date | null>(null);
  const [dateType, setDateType] =
    useState<DateRowRadioOptions>(getTypeForNewRow());
  const [previousDates, setPreviousDates] = useState<any>({
    settlementDate: null,
    observationDate: null,
  });

  const [multipleSettlementDates, setMultipleSettlementDates] = useState<
    Date[]
  >([]);
  const [multipleObservationDates, setMultipleObservationDates] = useState<
    Date[]
  >([]);
  const handleMultiDateAdd = () => {
    let newDates: any[] = [];
    if (
      multipleSettlementDates?.length > 0 &&
      multipleObservationDates?.length > 0 &&
      multipleObservationDates?.length !== multipleSettlementDates?.length
    ) {
      notifications.show({
        title: 'Dates Lists are of different lengths',
        message:
          'Please make sure the number of settlement dates and observation dates are the same',
        color: 'red',
        withCloseButton: true,
        autoClose: 10000,
      });
    } else if (
      multipleSettlementDates?.length > 0 &&
      multipleObservationDates?.length > 0
    ) {
      newDates = [...multipleSettlementDates].map((x, index) => ({
        settlementDate: x,
        dateType: dateType,
        observationDate: multipleObservationDates[index],
        id: v4(),
      }));
    } else if (multipleSettlementDates?.length > 0) {
      newDates = [...multipleSettlementDates].map((x) => ({
        settlementDate: x,
        dateType: dateType,
        observationDate: null,
        id: v4(),
      }));
    } else if (multipleObservationDates?.length > 0) {
      newDates = [...multipleObservationDates].map((x) => ({
        settlementDate: null,
        dateType: dateType,
        observationDate: x,
        id: v4(),
      }));
    }
    if (newDates?.length > 0) {
      handleAddMultipleDates(newDates);
      setMultipleSettlementDates([]);
      setMultipleObservationDates([]);
    }
  };

  const onAddDate = () => {
    handleAddDate({
      settlementDate: settlementDate,
      dateType: dateType,
      observationDate: observationDate,
      id: v4(),
    });
    setPreviousDates({
      settlementDate: formatDate(settlementDate),
      observationDate: formatDate(observationDate),
    });
  };

  const handleSettlementDateChange = (value: any) => {
    setSettlementDate(value);
  };
  const settlementDateError = getDateError(settlementDate);
  const observationDateError = getDateError(observationDate);

  const handleIncrementMonth = () => {
    if (observationDate) {
      setObservationDate(
        dayjs(formatDate(observationDate)).add(1, 'month').toDate(),
      );
    }
    if (settlementDate) {
      setSettlementDate(
        dayjs(formatDate(settlementDate)).add(1, 'month').toDate(),
      );
    }
  };

  const dateTypeRadioGroup = (
    <Radio.Group
      name={'new Date Group'}
      size="xs"
      value={dateType}
      onChange={(value: string) => setDateType(value as DateRowRadioOptions)}
    >
      <Radio value={DateRowRadioOptions.CALL} label="Call" />
      <Radio value={DateRowRadioOptions.COUPON} label="Coupon" />
      <Radio value={DateRowRadioOptions.BOTH} label="Both" />
    </Radio.Group>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        {(previousDates.settlementDate != null ||
          previousDates.observationDate != null) && (
          <div>
            Previous Date Entered:
            <br />
            {previousDates?.settlementDate &&
              'Settlement: ' + previousDates.settlementDate}
            {previousDates?.observationDate &&
              previousDates?.settlementDate &&
              ', '}
            {previousDates?.observationDate &&
              'Observation: ' + previousDates.observationDate}
          </div>
        )}
        {multiDateEntryMode ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: '2em',
              rowGap: '.5em',
              alignItems: 'center',
            }}
          >
            <DatePickerInput
              clearable={true}
              type="multiple"
              numberOfColumns={numberOfDateColumns}
              label={`Observation Dates ${multipleObservationDates.length > 0 ? `:  ${multipleObservationDates.length}` : ''}`}
              placeholder="Obs. dates"
              value={multipleObservationDates}
              onChange={setMultipleObservationDates}
              mx="auto"
              valueFormat={'MM-DD-YYYY'}
              popoverProps={{ position: 'right-start' }}
              w={130}
            />
            <DatePickerInput
              clearable={true}
              type="multiple"
              numberOfColumns={numberOfDateColumns}
              label={`Settlement Dates ${multipleSettlementDates.length > 0 ? `:  ${multipleSettlementDates.length}` : ''}`}
              placeholder="Settlement Dates"
              value={multipleSettlementDates}
              onChange={setMultipleSettlementDates}
              mx="auto"
              valueFormat={'MM-DD-YYYY'}
              popoverProps={{ position: 'left-start' }}
              w={130}
            />
            {dateTypeRadioGroup}
            <Button
              disabled={
                multipleSettlementDates?.length === 0 &&
                multipleObservationDates?.length === 0
              }
              type={'button'}
              className={'basic-button'}
              onClick={handleMultiDateAdd}
            >
              Add Multiple Dates
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: '2em',
              rowGap: '.5em',
              alignItems: 'center',
            }}
          >
            <DateInput
              hideOutsideDates={true}
              disabled={
                form.values.productYield.paymentType === CouponTypes.FIXED &&
                !form.values.productCall.callType
              }
              clearable={true}
              dateParser={quickInputDateParser}
              allowDeselect={true}
              valueFormat={'MM/DD/YYYY'}
              label={'Observation Date'}
              autoComplete={'off'}
              placeholder="MM/DD/YYYY"
              firstDayOfWeek={0}
              value={observationDate}
              excludeDate={isWeekend}
              error={observationDateError}
              onChange={(value) => setObservationDate(value)}
            />

            <DateInput
              hideOutsideDates={true}
              clearable={true}
              dateParser={quickInputDateParser}
              allowDeselect={true}
              valueFormat={'MM/DD/YYYY'}
              label={'Settlement Date'}
              autoComplete={'off'}
              placeholder="MM/DD/YYYY"
              firstDayOfWeek={0}
              value={settlementDate}
              excludeDate={isWeekend}
              error={settlementDateError}
              // onChange={(value) => setSettlementDate(value)}
              onChange={handleSettlementDateChange}
            />

            {dateTypeRadioGroup}

            <Button
              variant="primary"
              type={'button'}
              disabled={settlementDate == null && observationDate == null} // || observationDateError || settlementDateError
              onClick={onAddDate}
            >
              Add Date
            </Button>
            <Button
              variant={'secondary'}
              type={'button'}
              onClick={handleIncrementMonth}
            >
              Increment Month
            </Button>
          </div>
        )}
        <Tooltip
          label={
            'Toggle the date inputs to be for entering single dates or multiple dates'
          }
        >
          <div>
            <Switch
              label={multiDateEntryMode ? 'Multi' : 'Single'}
              onClick={() => setMultiDateEntryMode(!multiDateEntryMode)}
            />
          </div>
        </Tooltip>
        {multiDateEntryMode && (
          <Tooltip label={'Number of calendars to show when using date inputs'}>
            <div>
              <Select
                leftSectionWidth={50}
                leftSection={
                  <Group gap={4}>
                    <IconCalendar size={16} />
                    <IconHash size={16} />
                  </Group>
                }
                style={{ width: '6em' }}
                clearable={false}
                data={['1', '2', '3', '4', '5']}
                value={'' + numberOfDateColumns}
                onChange={(value: string | null) =>
                  setNumberOfDateColumns(value ? +value : 1)
                }
              />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default NewCalendarDate;
