import { useContext } from 'react';
import { NumberInput, NumberInputProps } from '@mantine/core';
import { FormFieldPath } from './FieldInput.interface';
import { isFieldDisabled } from '../../../../utilities/utilities';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { ProductFormHiddenFieldsContext } from '../../entry-and-edit-contexts/HiddenFields-context';
import { useProductFormDisabled } from '../../entry-and-edit-contexts/disabled-fields-context';
import { derivableFieldValues } from '../../../../product-schema/pdw-field-subsets';

export type AdjustedNumberInputProps = Omit<
  NumberInputProps,
  'parser' | 'formatter'
>;

export interface PFNumberInputProps
  extends AdjustedNumberInputProps,
    React.RefAttributes<HTMLInputElement>,
    FormFieldPath {
  parser?: (value: string | undefined) => string | undefined | number;
  formatter?: (value: string) => string | number | undefined;
}

const PFNumberInput = ({ fieldPath, ...rest }: PFNumberInputProps) => {
  const disabledContext = useProductFormDisabled();
  const form = useProductFormContext();
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
  // value is seperated out because resetting the form works in peculiar ways depending on initial values of inputs
  const { value } = form.getInputProps(fieldPath);
  return (
    <>
      {(!hiddenFieldsContext.hiddenFields.includes(fieldPath) ||
        hiddenFieldsContext.showAllHiddenFields) && (
        <NumberInput
          thousandSeparator={true}
          autoComplete={'off'}
          hideControls={true}
          {...form.getInputProps(fieldPath)}
          value={value != null && value !== '' ? +value : ''}
          maw={280}
          {...rest}
          styles={{
            label: {
              textDecoration: derivableFieldValues.includes(fieldPath)
                ? 'underline'
                : 'unset',
            },
            ...rest.styles,
          }}
          disabled={
            rest.disabled || isFieldDisabled(disabledContext, fieldPath)
          }
        ></NumberInput>
      )}
    </>
  );
};

export const isFieldHidden = (context: any, fieldPath: string): boolean => {
  return (
    context.hiddenFields.includes(fieldPath) && !context.showAllHiddenFields
  );
  // return !(context.hiddenFields.includes(fieldPath) || context.showAllHiddenFields);
};

export default PFNumberInput;
