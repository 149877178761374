import React, { useContext } from 'react';
import { DateInput, DateInputProps } from '@mantine/dates';
import { isFieldDisabled } from '../../../../utilities/utilities';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { ProductFormHiddenFieldsContext } from '../../entry-and-edit-contexts/HiddenFields-context';
import { FormFieldPath } from './FieldInput.interface';
import { useProductFormDisabled } from '../../entry-and-edit-contexts/disabled-fields-context';
import { quickInputDateParser } from '../../../../utilities/parcers-and-formatters/quick-input-date-parser';
import { derivableFieldValues } from '../../../../product-schema/pdw-field-subsets';

export interface PFDatePickerProps
  extends DateInputProps,
    React.RefAttributes<HTMLInputElement>,
    FormFieldPath {}
const PFDatePicker = ({ fieldPath, ...rest }: PFDatePickerProps) => {
  const disabledContext = useProductFormDisabled();
  const form = useProductFormContext();
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
  // value is seperated out because resetting the form works in peculiar ways depending on initial values of inputs
  const { value, onChange, onFocus, onBlur, error } =
    form.getInputProps(fieldPath);
  return (
    <>
      {(!hiddenFieldsContext.hiddenFields.includes(fieldPath) ||
        hiddenFieldsContext.showAllHiddenFields) && (
        <DateInput
          dateParser={quickInputDateParser}
          allowDeselect={true}
          styles={{
            label: {
              textDecoration: derivableFieldValues.includes(fieldPath)
                ? 'underline'
                : 'unset',
            },
          }}
          valueFormat={'MM/DD/YYYY'}
          autoComplete={'off'}
          placeholder="MM/DD/YYYY"
          firstDayOfWeek={0}
          hideOutsideDates={true}
          // popoverProps={{ disabled: true }} // can use to turn off popover altogether
          {...{ onChange, onFocus, onBlur, error }}
          value={value ? value : ''}
          maw={280}
          {...rest}
          disabled={
            rest.disabled || isFieldDisabled(disabledContext, fieldPath)
          }
        />
      )}
    </>
    // {...form.getInputProps(fieldPath)}
  );
};

export default PFDatePicker;
